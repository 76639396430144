const COMPANY_NAME = "Alfredo Leads";

export const COPY = {
  languages: ["pt", "en"],
  pt: {
    agent: "O seu consultor:",
    agency: "A sua agência:",
    content: {
      title: "Para não perder dinheiro na venda da sua casa",
      subTitle: "FAÇA VOCÊ MESMO A AVALIAÇÃO DO SEU IMÓVEL",
    },
    disclaimer:
      "A utilização desta plataforma é gratuita e pretende fornecer um valor expetável de venda de um imóvel determinado, tendo por base uma análise estatística. Os resultados gerados são estimativas e fornecem valores meramente indicativos, obtidos a partir da análise estatística de bases de dados de imóveis e outros indicadores de mercado imobiliário.",
  },
  en: {
    agent: "Your agent:",
    agency: "Your agency:",
    content: {
      title: "Don't lose money when selling your home",
      subTitle: "MAKE THE VALUATION OF YOUR PROPERTY",
    },
    disclaimer:
      "The use of this platform is free and aims to provide an expected sale value of a specific property, based on a statistical analysis. The results generated are estimates and provide merely indicative values, obtained from the statistical analysis of real estate databases and other real estate market indicators.",
  },
};
